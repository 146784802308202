import React from "react";
import {API_URL, API_URL_V2} from "../constants/API"
import Axios from "axios"
import { Tabs, Tab} from 'react-bootstrap';
import OutTransfer from "../components/OutTransfer"
import Order from "../components/Order"
import Inventory from "../components/Inventory"
import { getPathName,resetStatusCollection,getTransfer,getOrder, getInventory} from '../redux/actions/getData';
import { getTokenData } from "../redux/actions/token"
import { connect } from "react-redux"
import  { Redirect } from 'react-router-dom'
import moment from "moment"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';



class addressDetail extends React.Component{
  
  constructor(){
		super()
    this.state = {
      DataTransfer: [],
      notFound: false,
      notFoundOrder: false,
      notFoundInven: false,
      notFoundBalance: false,
      ethNotFound: false,
      tokenAnotherEth: [],
      balanceDataMain: [],
      DataOrder: [],
      DataInven: [],
      balanceData: [],
      tokenUtama: [],
      stateToken: [],
      ethToken: [],
      prevCursorTransfer: [""],
      pageTransfer: 1,
      prevCursorOrder: [""],
      pageOrder: 1,
      prevCursorInventory: [""],
      newData: "",
      pageInventory: 1,
      assetDetail: [],
    }
    this.nextPageTransfer = this.nextPageTransfer.bind(this)
		this.prevPageTransfer = this.prevPageTransfer.bind(this)
    this.nextPageOrder = this.nextPageOrder.bind(this)
		this.prevPageOrder = this.prevPageOrder.bind(this)
    this.nextPageInventory = this.nextPageInventory.bind(this)
		this.prevPageInventory = this.prevPageInventory.bind(this)
  }


  prevPageTransfer = () => {
    this.setState({
      pageTransfer: this.state.pageTransfer - 1
    })
    this.props.getTransfer(10, this.props.match.params.address_id, this.state.prevCursorTransfer[this.state.pageTransfer - 2])
  }

  prevPageOrder = () => {
    this.setState({
      pageOrder: this.state.pageOrder - 1
    })
    this.props.getOrder(10, this.props.match.params.address_id, this.state.prevCursorOrder[this.state.pageOrder - 2])
  }

  prevPageInventory = () => {
    this.setState({
      pageInventory: this.state.pageInventory - 1
    })
    this.props.getInventory(12, this.props.match.params.address_id, this.state.prevCursorInventory[this.state.pageInventory - 2])
  }
  

  nextPageTransfer = () => {
    if(this.state.prevCursorTransfer.length <= this.state.pageTransfer){
      this.setState({
        prevCursorTransfer: [...this.state.prevCursorTransfer, this.props.nextCursorTransfer],
        pageTransfer: this.state.pageTransfer + 1
      })
    }else{
      this.setState({
        pageTransfer: this.state.pageTransfer + 1
      })
    }
    this.props.getTransfer(10, this.props.match.params.address_id, this.props.nextCursorTransfer)
  }

  nextPageOrder = () => {
    if(this.state.prevCursorOrder.length <= this.state.pageOrder){
      this.setState({
        prevCursorOrder: [...this.state.prevCursorOrder, this.props.nextCursorOrder],
        pageOrder: this.state.pageOrder + 1
      })
    }else{
      this.setState({
        pageOrder: this.state.pageOrder + 1
      })
    }
    this.props.getOrder(10, this.props.match.params.address_id, this.props.nextCursorOrder)
  }

  nextPageInventory = () => {
    if(this.state.prevCursorInventory.length <= this.state.pageInventory){
      this.setState({
        prevCursorInventory: [...this.state.prevCursorInventory, this.props.nextCursorInventory],
        pageInventory: this.state.pageInventory + 1
      })
    }else{
      this.setState({
        pageInventory: this.state.pageInventory + 1
      })
    }
    this.props.getInventory(12, this.props.match.params.address_id, this.props.nextCursorInventory)
  }




    fetchBalances = () => {
        const dataNow= []
        Axios.get(`${API_URL_V2}/balances/${this.props.match.params.address_id}`)
        .then((result) => {
            //console.log(result.data.result)
            if(result.data.result.length){
                this.setState({
                  balanceData: result.data.result,
                  balanceDataMain: result.data.result[0],
                  notFoundBalance: false,
              })
            }else{
              this.setState({
                notFoundBalance: true,
              })
            }
            result.data.result.map((val) => {
                if(val.symbol === "ETH"){
                   dataNow.push(val)
                   this.setState({
                       tokenUtama: val,
                       ethNotFound: false,
                   })
                }
            })
        })
    }

    fetchToken = () => {
        Axios.get(`${API_URL}/tokens`)
        .then((result) => {
            this.setState({
                stateToken: result.data.result,
                ethToken: result.data.result[0],
            })
        })
    }

    fetchAsssets = (token_address, token_id, cursor) => {
      var additional = "";
      if(cursor){
          additional = `&cursor=${cursor}`
        }
      Axios.get(`${API_URL}/assets/${token_address}/${token_id}${additional}`)
      .then((result) => {
         this.setState({
             assetDetail: result.data,
             assetMetaDataName: result.data.metadata.name,
             asssetMetaData: result.data.metadata,
             loading: false,
         })
         //this.fetchAsssets(this.props.OutTransferData.token.data.token_address, this.props.OutTransferData.token.data.token_id)
         
      })

    }
    getToken() {
      Axios.get(`https://api.x.immutable.com/v2/balances/${this.props.match.params.address}`)
      .then(token => {
        this.setState({
          checkedToken: true,
          tokens: token.data.result,
        })
        if(this.state.tokens.some(symbol => symbol.symbol === "ETH")){
          token.data.result.map(token => {
            return(
              token.symbol === "ETH" ?
                this.setState({
                  checkedBalance: true,
                  balance: token.balance,
                  withdrawable: token.withdrawable
                })
              : null
            )
          })
        }else{
          this.setState({
            checkedBalance: true,
            balance: 0,
            withdrawable: 0
          })
        }
      })
      .catch(err => {
        this.setState({
          checkedToken: true,
        })
      })
    }

   outData = () => {
     const arrresult = []
     const transfers = this.props.transfer
     var totalData =  0;
     var colClass1 = "col-7 col-md-2"
     var colClass2 = "col-5 col-md-2"
     var colClass3 = "col-12 col-md-3"
     var colClass4 = "col-12 col-md-3"

    // console.log(this.props.transfer)

     if(transfers.length > 0) {
       while(totalData < this.props.transfer.length){
         arrresult.push(
          <a href={"/tx/"+transfers[totalData].transaction_id}>
           <div className="row" style={{wordBreak: "break-all", fontSize: "14px"}}>
            <div className="row">
              <div className={colClass1}>
              <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} className="badge rounded-pill bg-success">{parseInt(transfers[totalData].transaction_id).toLocaleString()}</p>
              </div>
              <div className={colClass1}>
              <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-clock-fill mx-2" style={{color: "#2081e2", fontSize: "12px"}}></i><span className="text-muted">{moment(transfers[totalData].timestamp).fromNow()}</span></p>
              </div>
              {
                //console.log(this.props.transfer)
              }
              <div className={colClass2}>
                {
                  transfers[totalData].type === "ERC721" && transfers[totalData].token_address !== "0xa7aefead2f25972d80516628417ac46b3f2604af" ?
                  <p className="text-hash"><img src={transfers[totalData].image_url} alt={transfers[totalData].symbol} className="image-validator" /><b>{transfers[totalData].quantity}</b> {transfers[totalData].token_name}</p>
                  : transfers[totalData].type === "ERC721" && transfers[totalData].token_address === "0xa7aefead2f25972d80516628417ac46b3f2604af" ?
                  <p className="text-hash"><img src="https://cdn.discordapp.com/attachments/890238141687009300/935139012425949224/ercc721.png" alt="Ethereum" className="image-validator" style={{width: "25px", height: "28px"}} /><b>{(parseInt(transfers[totalData].quantity)).toLocaleString(undefined, {maximumFractionDigits: 8})}</b></p>
                  :transfers[totalData].token_address === "0xa7aefead2f25972d80516628417ac46b3f2604af" ?
                    <p className="text-hash"><img src="https://design-system.immutable.com/currency_icons/currency--erc20.svg" alt="Ethereum" className="image-validator" /><b>{(parseInt(transfers[totalData].quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> GOG</p>
                  :transfers[totalData].token_address === "0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62" ?
                  <p className="text-hash"><img src="https://design-system.immutable.com/currency_icons/currency--erc20.svg" alt="GOG" className="image-validator" /><b>{(parseInt(transfers[totalData].quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> GOG</p>
                    : transfers[totalData].type === "ERC20" ?
                    <a href={"/address/"+transfers[totalData].token_address}><p className="text-hash"><img src={transfers[totalData].image_url} alt={transfers[totalData].token_name} className="image-validator" /><b>{(parseInt(transfers[totalData].quantity)/10**transfers[totalData].decimal).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> {transfers[totalData].symbol}</p></a>
                  : <p className="text-hash"><img src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="Ethereum" className="image-validator" /><b>{(parseInt(transfers[totalData].quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> ETH</p>
                }
              </div>
              <div className={colClass3}>
                <a href={"/address/"+transfers[totalData].from} onClick={() => this.changePath("/address")}><p className="text-hash"><i className="bi bi-arrow-up text-danger"></i> <i className="bi bi-person"></i>{transfers[totalData].from}</p></a>
              </div>
              <div className={colClass4}>
                <a href={"/address/"+transfers[totalData].to} onClick={() => this.changePath("/address")}><p className="text-hash"><i className="bi bi-arrow-down" style={{color: "#28A745"}}></i> <i className="bi bi-person"></i>{transfers[totalData].to}</p></a>
              </div>
            </div>
          </div>
          <hr style={{marginTop: "0px"}} />
        </a>
         )
         totalData++
       }
     }
     return arrresult
   }



    renderAll = () => {
        this.fetchBalances()
        this.fetchToken() 
    }

    checkImage = (e) => {
      e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
    }
  
    imageSwitch(){
      if(this.state.balanceDataMain.symbol === "ETH"){
        //console.log("eth");
        return <img className="" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="coin icon" style={{width: "20px"}} />
      }
      else{
        return this.props.tokenGlobal.tokenList.map((val) => {
          if(val.symbol === this.state.balanceDataMain.symbol){
            return <img className="mx-1" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "18px"}} />
          }
        })
      }
    }
    

    componentDidMount(){
        this.props.getTokenData()
        document.title = `Address ${this.props.match.params.address_id}`; 
        this.renderAll()
        this.props.getTransfer(10,this.props.match.params.address_id)
        this.props.getOrder(10, this.props.match.params.address_id)
        this.props.getInventory(12, this.props.match.params.address_id)
    }

    render(){
        return(
          <div>
            {
             console.log(this.state.balanceDataMain)
            }
            {
              this.state.notFound && this.state.notFoundOrder && this.state.notFoundOrder && this.state.notFoundBalance === true ?
               <div className="setBody">
                  <Redirect to="/"></Redirect>
               </div>

              :
              <div className="setBody mt-4">
               {
                   this.props.transfer.length >= 0 ?
                   <div>
                   <div className="shadow-sm p-3 mb-5 bg-white rounded">
                   <strong className="mx-2" style={{fontSize: "16px"}}>Address Detail </strong> 
                   <hr /> 
                    <div className="row mx-2" style={{wordBreak: "break-all", fontSize: "16px"}}>
                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">Address</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                     
                      <p className="font" style={{}}>
                      {this.props.match.params.address_id}
                      <Tooltip title="Copy" placement="top">
                      <i className="copy-icon bi bi-files mx-1" onClick={() => {navigator.clipboard.writeText(this.props.match.params.address_id)}}></i>
                      </Tooltip>
                      </p>                    
                    </div>

                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                      {
                        //console.log(this.state.balanceData.length)
                      }
                    <p className="font">Balance </p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                      {
                        //console.log(this.state.balanceDataMain)
                      }
                    {     
                          this.state.balanceData.length === 0 ?
                          <p className="font" style={{}}><img src="https://design-system.immutable.com/currency_icons/currency--eth.svg" style={{width: "20px"}} alt="token" />{this.state.balanceData.length} ETH</p>
                          :
                          this.state.tokenUtama.balance === undefined ?
                            <p className="font" style={{}}>{this.imageSwitch()}{(this.state.balanceDataMain.balance/(10**18)).toLocaleString(undefined, {maximumFractionDigits: 3})} {this.state.balanceDataMain.symbol}</p>
                          :                  
                            <p className="font" style={{}}><img src="https://design-system.immutable.com/currency_icons/currency--eth.svg" style={{width: "20px"}} alt="token" />{(this.state.tokenUtama.balance/(10**this.state.ethToken.decimals)).toLocaleString(undefined, {maximumFractionDigits: 3})} {this.state.tokenUtama.symbol}</p>
                            
                        }
                    </div>
                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">Detail Balances</p>                   
                    </div>
                    <div className="col-12 col-md-5" >
                      <Accordion>
                       <AccordionSummary
                           expandIcon={<ExpandMoreIcon />}
                           aria-controls="panel1a-content"
                           id="panel1a-header"
                           >
                           <Typography>{this.state.balanceData.length} Tokens</Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                               {
                                   this.state.balanceData.map((val) => {
                                       
                                       return(
                                           <p>{(val.balance/(10**this.state.ethToken.decimals)).toLocaleString(undefined, {maximumFractionDigits: 3})} 
                                           <span className="font font-Weight-bold" style={{fontWeight: "bold"}}> {val.symbol} </span>
                                           <hr />
                                           </p>
                                       )
                                   })
                               }
                           </AccordionDetails>
                       </Accordion>
                    </div>
                    
                     </div>
                   </div>

                   <div className="shadow-sm p-3 mb-5 bg-white rounded">
                        <p className="mt-2" style={{fontWeight: "bold", fontSize: "16px"}}>Last Transfer</p>  
                        <hr />            
                      <div className="card-body bg-muted">
                      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                       <Tab eventKey="home" title="Out">
                           {
                             this.props.transfer === undefined ?
                             <div className="content setBody" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
                              <div class="o1"></div>
                              <div class="o2"></div>
                              <div class="o3"></div>
                            </div>
                             : 
                             <div className="mx-2">
                               {
                                  this.outData()
                               }
                           </div>
                           }
                           <div className="text-center mt-2">
                            <button disabled={this.state.pageTransfer === 1} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.prevPageTransfer}><i className="mx-1 bi bi-arrow-left"></i>{"Prev"}</button>
						              	<button disabled={this.props.dataRemainingTransfer <= 0}  className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.nextPageTransfer}>{"Next"}<i className="mx-1 bi bi-arrow-right"></i></button>
                            </div>
                       </Tab>
                       <Tab eventKey="order" title="Order">
                          {
                            this.props.order === undefined ?
                            <div className="content setBody" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
                              <div class="o1"></div>
                              <div class="o2"></div>
                              <div class="o3"></div>
                            </div>
                            :
                            <div className="mx-2">
                             {
                               this.props.order.map(val => {
                                 return(
                                   <Order orderData ={val} />
                                 )
                               })
                             }
                          </div>
                          }
                          <div className="text-center mt-2">
                            <button disabled={this.state.pageOrder === 1} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.prevPageOrder}><i className="mx-1 bi bi-arrow-left"></i>{"Prev"}</button>
						                <button disabled={this.props.dataRemainingOrder <= 0} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.nextPageOrder}>{"Next"}<i className="mx-1 bi bi-arrow-right"></i></button>
                            </div>
                          </Tab>
                       <Tab eventKey="inventory" title="Inventory">
                          {
                            this.props.inventory === undefined ?
                            <div className="content setBody" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
                              <div class="o1"></div>
                              <div class="o2"></div>
                              <div class="o3"></div>
                            </div>
                            :
                            <div className="row">
                            {
                              this.props.inventory.map(val => {
                                return(
                                  <Inventory inventoryData={val} />
                                )
                              })
                            }
                            <div className="text-center mt-4">
                              <button disabled={this.state.pageInventory === 1} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.prevPageInventory}><i className="mx-1 bi bi-arrow-left"></i>{"Prev"}</button>
                              <button disabled={this.props.dataRemainingInventory <= 0} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.nextPageInventory}>{"Next"}<i className="mx-1 bi bi-arrow-right"></i></button>
                              </div>
                          </div>
                          }
                       </Tab>
                     </Tabs>
                      </div>
                   </div>
               </div>
               :
               <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
			  <div class="o1"></div>
			  <div class="o2"></div>
			  <div class="o3"></div>
			 </div>
               }
              </div>
            }
            
          </div>
        )
    }
}

const mapStateToProps = (state) => {
	return {
	  pathName: state.user.pathName,
    transfer: state.user.latestTransfer,
    nextCursorTransfer: state.user.nextCursorTransfer,
    dataRemainingTransfer: state.user.dataRemainingTransfer,
    order: state.user.latestOrder,
    nextCursorOrder: state.user.nextCursorOrder,
    dataRemainingOrder: state.user.dataRemainingOrder,
    inventory: state.user.latestInventory,
    nextCursorInventory: state.user.nextCursorInventory,
    dataRemainingInventory: state.user.latestInventory,
    tokenGlobal: state.token,
	}
  };

  const mapDispatchToProps = {
	getPathName,
	resetStatusCollection,
  getTransfer,
  getTokenData,
  getOrder,
  getInventory,
  };

export default connect(mapStateToProps, mapDispatchToProps)(addressDetail);