import React from "react"
import "../assets/css/styles.css"
import Axios from "axios"
import {API_URL} from "../constants/API"
import { connect } from 'react-redux'
import { getTokenData } from "../redux/actions/token"
import  { Redirect } from 'react-router-dom'
import { getSellPets, resetStatusCollection, getOfferPets } from '../redux/actions/getData';
import moment from "moment"
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';

class assetPets extends React.Component{
	constructor(){
		super()
		this.state = {
			datasAsset: [],
			datasMain: [],
			datasMainSell: [],
			datasMainData: [],
			setTitle: "",
			datasMainSellProperties: [],
			getTokenAddress: "",
			getTokenType: "",
			datasBuyData: [],
			metaData: [],
			asssetData: [],
			assetDataCollection: [],
			dataNotFound: false,
			assetNotFound: false,
			prevCursorSell: [""],
			pageSell: 1,
			prevCursorOffer: [""],
			pageOffer: 1,
		}
		this.nextPageSell = this.nextPageSell.bind(this)
		this.prevPageSell = this.prevPageSell.bind(this)
		this.nextPageOffer = this.nextPageOffer.bind(this)
		this.prevPageOffer = this.prevPageOffer.bind(this)
	}

	

	fetchAsset = () => {
		Axios.get(`${API_URL}/orders?sell_token_id=${this.props.match.params.token_id}&sell_token_address=0xf797fa8b22218f4a82286e28a2727cd1680f4237&page_size=200`)
		.then((result) => {
			if(result.data.result.length){
				this.setState({
					datasAsset: result.data.result,
					datasMain: result.data.result[0],
					datasMainSell: result.data.result[0].sell,
					datasMainData: result.data.result[0].sell.data,
					datasMainSellProperties: result.data.result[0].sell.data.properties,
					getTokenAddress: result.data.result[0].buy.data.token_address,
					getTokenType: result.data.result[0].buy.type,
					datasBuyData: result.data.result[0].buy.data,
					dataNotFound: false,
					sellmaxPage: Math.ceil(result.data.result.length / this.state.sellitemPerPage),
					//setTitle: `${result.data.result[0].sell.data.properties.name} #${this.props.match.params.token_id} | Guild of Guardins Scan`,
				})
			}else{
				this.setState({
					dataNotFound: true,
				})
			}
		})
	}

	fetchMetaData = () => {
		Axios.get(`${API_URL}/assets/0xf797fa8b22218f4a82286e28a2727cd1680f4237/${this.props.match.params.token_id}`)
		.then((result) => {
			if(result.data.token_address !== ""){
				this.setState({
					asssetData: result.data,
					metaData: result.data.metadata,
					assetDataCollection: result.data.collection,
					assetNotFound: false,
					setTitle:`${result.data.name} #${this.props.match.params.token_id} | Guild of Guardins Scan`,
				})
			}else{
				this.setState({
					assetNotFound: true,
				})
			}
			//console.log(result.data)
		}).catch(() => {
			this.setState({
				assetNotFound: true,
			})
		})
	}


	imageSwitch(){
		if(this.state.getTokenType === "ETH"){
			return <img className="" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="token" style={{width: "22px"}} />
		}
		else if(this.state.getTokenType === "ERC20"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.state.getTokenAddress){
					return <img className="px-1" src={val.image_url} onError={this.checkImage} alt="token"  style={{width: "22px"}} />
				}
			})
		}
	}

	checkImage = (e) => {
		e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
	}


	prevPageSell = () => {
		this.setState({
			pageSell: this.state.pageSell - 1
		})
		this.props.getSellPets(5, this.props.match.params.token_id, this.state.prevCursorSell[this.state.pageSell - 2])
	}

	prevPageOffer = () => {
		this.setState({
			pageOffer: this.state.pageOffer - 1
		})
		this.props.getOfferPets(5, this.props.match.params.token_id, this.state.prevCursorOffer[this.state.pageOffer - 2])
	}

	nextPageOffer = () => {
		if(this.state.prevCursorOffer.length <= this.state.pageOffer){
			this.setState({
				prevCursorOffer: [...this.state.prevCursorOffer, this.props.nextCursorOfferPets],
				pageOffer: this.state.pageOffer + 1
			})
		}else{
			this.setState({
				pageOffer: this.state.pageOffer + 1
			})
		}
		this.props.getOfferPets(5, this.props.match.params.token_id, this.props.nextCursorOfferPets)
	}


	nextPageSell = () => {
		if(this.state.prevCursorSell.length <= this.state.pageSell){
			this.setState({
				prevCursorSell: [...this.state.prevCursorSell, this.props.nextCursorSellPets],
				pageSell: this.state.pageSell + 1
			})
		}else{
			this.setState({
				pageSell: this.state.pageSell + 1
			})
		}
		this.props.getSellPets(5, this.props.match.params.token_id, this.props.nextCursorSellPets)
	}

	
	

	  filledSwitch = (param) => {
		switch(param){
			case 'filled':
				return 'badge rounded-pill bg-success'
			case 'active':
				return 'badge rounded-pill bg-info'
			case 'cancelled':
				return 'badge rounded-pill bg-danger'
			default:
				return ''
		}
	}

	


	componentDidMount(){
		this.fetchAsset()
		this.props.getTokenData()
		this.fetchMetaData()
		this.props.getSellPets(5, this.props.match.params.token_id)
		this.props.getOfferPets(5, this.props.match.params.token_id)
	}


	render(){
		return(
			<div>
				<Helmet>
					<title>{this.state.setTitle}</title>
				</Helmet>
				{
					console.log(this.props.offerPets)
				}
				{
					this.state.assetNotFound === false ?
					<div className="main">
						{
							//console.log(this.props.sellPets)
						}
						{
							this.state.dataNotFound === false ?
							<div className="setBody">
						{
							this.state.datasAsset.length > 0?
						<div className="row mt-5 mx-3">
						<div className="col-12 col-md-5" style={{marginRight: "20px"}}>
							<div className="card border-0" style={{borderRadius: "0px"}}>
								<img className="card-img-top setCard" src={this.state.datasMainSellProperties.image_url} alt="heroes_card" />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="mt-4 mx-2">
							<p className="h4" style={{fontWeight: "bold"}}>{this.state.datasMainSellProperties.name} #{this.state.datasMainData.token_id}</p>
							<Link to={`/address/${this.state.datasMain.user}`}>
							<i className="bi bi-person-fill h5 wrap" style={{color: "grey", marginRight:"5px"}}></i><span className="h6 wrap" style={{fontWeight: "bold", color:"grey", fontSize: "14px"}}>Owned By <span className="wrap" style={{color: "black"}}>{this.state.datasMain.user}</span></span>
							</Link>
							<p className=" font" style={{fontSize: "14px"}}><img src={this.state.datasMain.sell.data.properties.collection.icon_url} className="rounded-circle mx-1" alt="God_logo" style={{width: "16px"}} />{this.state.datasMain.sell.data.properties.collection.name}</p>
							</div>
							<div className="card mt-4 cardBorderRadius">
								<div className="card-header">
									<div className="row mt-3">
										<div className="col-8 col-md-8">
										<p  className="" style={{fontSize: "16px", color: "grey", fontWeight: "bold"}}><i class="bi bi-clock mx-2" style={{fontWeight: "bold"}}></i>Sell expired on {moment(this.state.datasMain.expiration_timestamp).format('lll')}</p>
										</div>
										<div className="col-4 col-md-4">
										<p style={{fontWeight: "bold", color: "grey"}} className="mx-1">Status: <span className="mx-1"></span><span className={this.filledSwitch(this.state.datasMain.status)} style={{fontSize: "13px",}}>{this.state.datasMain.status}</span></p>
										</div>
									</div>
								</div>
								<div className="card-body bg-muted" style={{fontSize: "14px"}}>
									<p style={{fontSize: "16px"}}>
									Current Price
									<br />
									{this.imageSwitch()} <span style={{fontSize: "20px", fontWeight: "bold"}}>{(this.state.datasBuyData.quantity/(10**this.state.datasBuyData.decimals)).toLocaleString(undefined, {maximumFractionDigits: 3})}</span> <span className="mx-1" style={{fontSize: "14px"}}>({this.state.getTokenType})</span>
									</p>
									{
									this.state.datasMain.status === "active" ? 
									<a href={`https://market.x.immutable.com/assets/0xf797fa8b22218f4a82286e28a2727cd1680f4237/${this.props.match.params.token_id}`} rel="noreferrer" target="_blank" >
									<button type="button" className=" btnblue btn btn-primary btn-lg text-center col-5 text text-center" style={{outline: "none", fontSize: "16px"}}>Buy Now</button>
									</a>
									: 
									null
									}
								</div>	
							</div>

							<div className="card mt-4 cardBorderRadius">
								<div className="card-header">
									<p className="mt-3" style={{fontSize: "16px", color: "black", fontWeight: "bold"}}><i class="bi bi-collection mx-2"></i>Meta Data</p>
								</div>
								<div className="card-body" style={{fontSize: "14px"}}>
									<div className="row mx-2">
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Type</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.type}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Class</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.class}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Series</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.series}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>AssetId</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.assetId}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>RarityStr</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.rarityStr}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Serial Number</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.serialNumber}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Special Edition</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.specialEditionStr}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Animation Url</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.animation_url_mime_type}</span>
										  </p>
										  </div>
										 </div>
										</div>
									</div>
								</div>
							</div>

							
						</div>
						<div className="mt-3">
							<Accordion>
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<strong style={{fontSize: "16px"}} className="mt-1"><i class="bi bi-arrow-up-circle mx-2" style={{}}></i>Sell History</strong>
								<hr style={{color: "#2081e2"}} />
								</AccordionSummary>
								<AccordionDetails>
								{
									this.props.sellPets === undefined ?
									<div className="content setBody" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
										<div class="o1"></div>
										<div class="o2"></div>
										<div class="o3"></div>
									</div>
									: 
									<div className="mx-2">
									{
										this.props.sellPets.map(val => {
											return(
												<div className="row" style={{fontSize: "14px"}}>
													<div className="col-6 col-md-2">
													<Tooltip title={val.order_id} placement="bottom">
														<p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} className="badge rounded-pill bg-success">{(val.order_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
													</Tooltip>
													</div>
													<div className="col-6 col-md-3">
														<p><i class="bi bi-clock-fill mx-2" style={{color: "#2081e2", fontSize: "12px"}}></i>
														<span className="text-muted">{moment(val.timestamp).fromNow()}</span>
														</p>
													</div>
													<div className="col-6 col-md-2">
														<p className="mx-2" style={{fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.imageSwitch()}{(val.buy.data.quantity/(10**val.buy.data.decimals)).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
													</div>
													<div className="col-12 col-md-5">
													<Tooltip title={val.user} placement="bottom">
														<p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-arrow-up mx-2" style={{color: "#DC3545"}}></i>{(val.user)}</p>
													</Tooltip>
													</div>	
													<hr />	
												</div>
											)
										})
									}
								</div>
								}

							<div className="text text-center mt-2">
								<button disabled={this.state.pageSell === 1} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.prevPageSell}><i className="mx-1 bi bi-arrow-left"></i>{"Prev"}</button>
								<button disabled={this.props.dataRemainingSellPets <= 0} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.nextPageSell}>{"Next"}<i className="mx-1 bi bi-arrow-right"></i></button>
								</div>
								</AccordionDetails>
							</Accordion>
						</div>
						
						<div className="mt-3">
							<Accordion>
								<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								>
								<strong style={{fontSize: "16px"}} className="mt-1"><i class="bi bi-lightning-charge mx-2" style={{color: ""}}></i>Offers</strong>
								<hr style={{color: "#2081e2"}} />
								</AccordionSummary>
								<AccordionDetails>
									{
										this.props.offerPets === undefined ?
										<div className="content setBody" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
											<div class="o1"></div>
											<div class="o2"></div>
											<div class="o3"></div>
										</div>
										: 
										<div className="" style={{fontSize: "14px"}}>
											{
												this.props.offerPets.map(val => {
													return(
														<div className="row" stle={{fontSize: "14px"}}>
																<div className="col-6 col-md-2">
																<Tooltip title={val.order_id} placement="bottom">
																	<p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} className="badge rounded-pill bg-success">{(val.order_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
																</Tooltip>
																</div>
																<div className="col-6 col-md-3">
																	<p><i class="bi bi-clock-fill mx-2" style={{color: "#2081e2", fontSize: "12px"}}></i>
																	<span className="text-muted">{moment(val.timestamp).fromNow()}</span>
																	</p>
																</div>
																<div className="col-6 col-md-2">
																	<p className="mx-2" style={{fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.imageSwitch()}{val.sell.data.quantity/(10**val.sell.data.decimals)}</p>
																</div>
																<div className="col-12 col-md-5">
																<Tooltip title={val.user} placement="bottom">
																	<p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-arrow-up mx-2" style={{color: "#DC3545"}}></i>{val.user}</p>
																</Tooltip>
																</div>
																<hr />	
														</div>
													)
												})
											}
										</div>
									}
							<div className="text text-center mt-2">
								<button disabled={this.state.pageOffer === 1} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.prevPageOffer}><i className="mx-1 bi bi-arrow-left"></i>{"Prev"}</button>
								<button disabled={this.props.dataRemainingOfferPets <= 0} className="btn btn-primary  btnblue btn-sm mx-1" style={{width: "80px"}} onClick={this.nextPageOffer}>{"Next"}<i className="mx-1 bi bi-arrow-right"></i></button>
								</div>
								</AccordionDetails>
							</Accordion>
							</div>
						</div>
						: 
						<div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
						<div class="o1"></div>
						<div class="o2"></div>
						<div class="o3"></div>
						</div>
						}
					</div>
					: 
					<div className="row setBody mt-5">
						<div className="col-12 col-md-5 mx-3" style={{marginRight: "20px"}}>
							<div className="card border-0" style={{borderRadius: "0px"}}>
								<img className="card-img-top setCard" src={this.state.metaData.image_url} alt="heroes_card" />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="mt-4 mx-2">
							<p className="h4" style={{fontWeight: "bold"}}>{this.state.asssetData.name} #{this.state.asssetData.token_id}</p>
							<Link to={`/address/${this.state.asssetData.user}`}>
							<i className="bi bi-person-fill h5 wrap" style={{color: "grey", marginRight:"5px"}}></i><span className="h6 wrap" style={{fontWeight: "bold", color:"grey", fontSize: "14px"}}>Owned By <span className="wrap" style={{color: "black"}}>{this.state.asssetData.user}</span></span>
							</Link>
							<p className=" font" style={{fontSize: "14px"}}><img src={this.state.assetDataCollection.icon_url} className="rounded-circle mx-1" alt="God_logo" style={{width: "16px"}} />{this.state.assetDataCollection.name}</p>
							</div>

							<div className="card mt-4 cardBorderRadius">
								<div className="card-header">
									<p className="mt-3" style={{fontSize: "16px", color: "black", fontWeight: "bold"}}><i class="bi bi-collection mx-2"></i>Meta Data</p>
								</div>
								<div className="card-body"  style={{fontSize: "14px"}}>
									<div className="row mx-2">
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Type</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.type}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Class</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.class}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Series</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.series}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>AssetId</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.assetId}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>RarityStr</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.rarityStr}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Serial Number</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.serialNumber}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Special Edition</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.specialEditionStr}</span>
										  </p>
										  </div>
										 </div>
										</div>
										<div className="col-6 col-md-4 mt-2">
										 <div className="border border-1 cardBorderRadius border-info" style={{backgroundColor: "#EDF9FE", minHeight: "60px", maxHeight: "60px"}}>
										  <div className="text-center mt-1">
										  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
										  <span className="" style={{fontSize: "14px", fontWeight: "bold"}}>Animation Url</span>
										  <br />
										  <span className="mx-3" style={{fontSize: "14px", color: "grey"}}>{this.state.metaData.animation_url_mime_type}</span>
										  </p>
										  </div>
										 </div>
										</div>
									</div>
								</div>
							</div>
				
						</div>
					</div>
					}
					</div>
					:
					<Redirect to="/" />
				}
			</div>
		)
	}
}



const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
		sellPets: state.user.latestSellPets,
		nextCursorSellPets: state.user.nextCursorSellPets,
		dataRemainingSellPets: state.user.dataRemainingSellPets,
		offerPets: state.user.latestOfferPets,
		nextCursorOfferPets: state.user.nextCursorOfferPets,
		dataRemainingOfferPets: state.user.dataRemainingOfferPets,
	}
}

const mapDispatchToProps = {
	getTokenData,
	getSellPets,
	resetStatusCollection,
	getOfferPets,
}

export default connect(mapStateToProps, mapDispatchToProps)(assetPets)