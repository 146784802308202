import React from 'react'
import "../assets/css/styles.css"
import { getPathName } from '../redux/actions/getData';
import { connect } from 'react-redux';

class MyNavbar extends React.Component {
  state = {
    searchTx : "",
    name: [],
    value: [],
  }
  componentWillMount() {
    this.props.getPathName(window.location.pathname);
  }
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }

  searchInputHandler = (event) => {
    const name = event.target.name
    const value = event.target.value

    this.setState({
      [name]: value
    })
  }

  searchButton = () => {
    //return window.location.replace(`/tx/${this.state.searchTx}`);
    console.log(this.state.searchTx.length)
    if(this.state.searchTx.length === 8){
      return window.location.replace(`/tx/${this.state.searchTx}`)
    }else if(this.state.searchTx.length === 42){
      return window.location.replace(`/address/${this.state.searchTx}`)
    }else{
      return window.location.replace(`/`)
    }
    
  }


  render() {
    return (
     <div>
       {
         //console.log(this.state.searchTx)
       }
       <div className="header shadown-sm" style={{fontSize: "14px"}}>
         <div className="header-left">
         <a className="header-link" href="/">
            <img src="https://cdn.discordapp.com/attachments/890238141687009300/932312203493769247/image.png" alt="Navbar_logo" className="thecodes-header" style={{width: "200px"}} />
         </a>

            <div className="header-menu-show">
              <i className="bi bi-text-left"></i>
            </div>

            <div className="header-right">
              <div className="search-header">
                  <input 
                  onChange={this.searchInputHandler}
                  type="text" placeholder="Search Transfers By TX ID, Address_User" style={{fontSize: "16px"}} name="searchTx" />
                  <button type="button" onClick={this.searchButton}><i className="icon bi-search"></i></button>
              </div>
              <a className="header-link" href="/heroes">
                <div className="header-menu" style={this.props.pathName === "/heroes" ? {color: "black", fontWeight: "bold"} : {color: "black"}} >
                  Heroes
                </div>
              </a>
              <a className="header-link" href="/pets">
               <div className="header-menu" style={this.props.pathName === "/pets" ? {color: "black", fontWeight: "bold"} : {color: "black"}} >
                  Pets
                </div>
              </a>
              <a className="header-link" href="/avatar">
              <div className="header-menu" style={this.props.pathName === "/avatar" ? {color: "black", fontWeight: "bold"} : {color: "black"}} >
                  Avatar
                </div>
              </a>
              <a className="header-link" href="/other">
              <div className="header-menu" style={this.props.pathName === "/other" ? {color: "black", fontWeight: "bold"} : {color: "black"}} >
                  Other
                </div>
              </a>
              <a className="header-link" href="/transfer">
              <div className="header-menu" style={this.props.pathName === "/transfer" ? {color: "black", fontWeight: "bold"} : {color: "black"}} >
                  Transfer
                </div>
              </a>
            </div>
         </div>
       </div>
     </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pathName: state.user.pathName
  }
}

const mapDispatchToProps = {
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyNavbar);