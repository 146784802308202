import React from "react"
import '../assets/css/styles.css';
import Axios from "axios"
import {API_URL} from "../constants/API"
import { getTokenData } from "../redux/actions/token"
import { connect } from "react-redux";

class tradesDetail extends React.Component {
    state  = {
        tradesData: [],
        dataValueA: [],
        dataValueB: [],
    }

    fetchTradesData = () => {
        Axios.get(`${API_URL}/trades/${this.props.match.params.td_id}`)
        .then((result) => {
            this.setState({
                tradesData: result.data,
                dataValueA: result.data.a,
                dataValueB: result.data.b,
            })
        })
    }

    checkImage = (e) => {
		e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
	}

	imageSwitcherc20(){
		if(this.state.dataValueA.token_type === "ETH"){
			//console.log("eth");
			return <img className="" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="coin icon" style={{width: "16px", marginRight: "5px"}} />
		}
		else if(this.state.dataValueA.token_type === "ERC20"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.state.dataValueA.token_address){
					return <img className="" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "16px", marginRight: "5px"}} />
				}
			})
		}

	}

    imageSwitcherc721(){
		if(this.state.dataValueB.token_type === "ETH"){
			//console.log("eth");
			return <img className="" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="coin icon" style={{width: "16px", marginRight: "5px"}} />
		}
		else if(this.state.dataValueB.token_type === "ERC721"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.state.dataValueB.token_address){
					return <img className="" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "16px", marginRight: "5px"}} />
				}
			})
		}

	}

    filledSwitch = (param) => {
		switch(param){
			case 'success':
				return 'badge rounded-pill bg-success'
			default:
        return 'badge rounded-pill bg-danger'
		}
	}

    componentDidMount() {
        document.title = `Trades ${this.props.match.params.td_id}`; 
        this.fetchTradesData()
        this.props.getTokenData()
    }

    render(){
        return(
            <div className="setBody">
                {
                    console.log(this.state.dataValueA.token_type)
                }
             <div className="col-md-12 col-12 mt-4 mx-4">
                <div className="col-12 col-md-12">
                   <div className="col-12 col-md-3 mx-4">
                   <strong className="mx-1">Trades Detail {this.props.match.params.td_id}</strong>  
                   </div>
                   <div className="row mx-4 mt-4">
                <div className="col-12 col-md-5 shadow p-3 mb-5 bg-white rounded" style={{marginRight: "50px", fontSize: "14px"}}>
                    <strong className="mx-1">Trades Detail {this.state.dataValueA.token_type}</strong>  
                    <hr />
                    <div className="row" style={{wordBreak: "break-all", fontSize: "14px"}}>
                         <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">TX ID</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                        <p className="font" style={{fontWeight: "bold"}}><i class="bi bi-check-circle-fill" style={{color: "#2081e2", marginRight: "5px"}}></i>{parseInt(this.state.tradesData.transaction_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                    </div>

                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">Status</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                        <p className="font" style={{fontWeight: "bold"}}>
                        <span className={this.filledSwitch(this.state.tradesData.status)}>{this.state.tradesData.status}</span>
                        </p>
                    </div>
                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">Order ID</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                        <p className="font" style={{fontWeight: "bold"}}><i class="bi bi-check-circle-fill" style={{color: "#2081e2", marginRight: "5px"}}></i>{parseInt(this.state.dataValueA.order_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                    </div>

                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">Price</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                        <p className="font" style={{fontWeight: "bold"}}>{this.imageSwitcherc20()}{(this.state.dataValueA.sold / (10 ** 18)).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                    </div>
                   
                    </div>
                </div>
                <div className="col-12 col-md-5 shadow p-3 mb-5 bg-white rounded">
                    <strong className="mx-1">Trades Detail {this.state.dataValueB.token_type}</strong>  
                    <hr />
                    <div className="row" style={{wordBreak: "break-all", fontSize: "14px"}}>
                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">TX ID</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                        <p className="font" style={{fontWeight: "bold"}}><i class="bi bi-check-circle-fill" style={{color: "#2081e2", marginRight: "5px"}}></i>{parseInt(this.state.tradesData.transaction_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                    </div>

                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">Status</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                        <p className="font" style={{fontWeight: "bold"}}>
                        <span className={this.filledSwitch(this.state.tradesData.status)}>{this.state.tradesData.status}</span>
                        </p>
                    </div>
                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">Order ID</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                        <p className="font" style={{fontWeight: "bold"}}><i class="bi bi-check-circle-fill" style={{color: "#2081e2", marginRight: "5px"}}></i>{parseInt(this.state.dataValueB.order_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                    </div>

                    <div className="col-12 col-md-3" style={{color: "#334670", fontWeight: "bold"}}>
                    <p className="font">Price</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                        {
                            this.state.dataValueB.sold === "1" ?
                            <p className="font" style={{fontWeight: "bold"}}>{this.imageSwitcherc20()}{(this.state.dataValueB.sold).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                            : <p className="font" style={{fontWeight: "bold"}}>{this.imageSwitcherc20()}{(this.state.dataValueB.sold / (10 ** 18)).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                        }
                    </div>
                   
                    </div>
                </div>
             </div>
                </div>
             </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
	}
}

const mapDispatchToProps = {
	getTokenData,
}


export default connect(mapStateToProps, mapDispatchToProps)(tradesDetail)