import React from "react";

class Inventory extends React.Component{
        state = {
            pagesTo: "",
        }

    fetchLinkSet = (link,token) => {
        //heroes
        if(link === "0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11"){
            return `../assets-heroes/${token}`
        }
        else if (link === "0x83f120283c30c796ebe9216ccaf6718c31213681"){
            return `../assets-avatar/${token}`
        }else if (link === "0x56a900b85d309e0a981d59377ea76f12dcd4b8de"){
            return `../assets-other/${token}`
        }else if(link === "0xf797fa8b22218f4a82286e28a2727cd1680f4237"){
            return `../assets-pets/${token}`
        }else{
            return `https://market.x.immutable.com/assets/${link}/${token}`
        }
    }

    render(){
        return(
            <div className="col-6 col-md-2 mt-3">
                 <a href={this.fetchLinkSet(this.props.inventoryData.token_address, this.props.inventoryData.token_id)} target="_blank">
                 <div className="card cardImage border border-0" style={{width: "100%"}}>
                <img className="card-img-top setCard cardImage" src={this.props.inventoryData.image_url} alt="Inventory Card"/>
                    <div className="text mt-1 mx-2 text-center">
                        <span style={{fontSize: "11px", fontWeight: "bold", color:"grey"}}>{this.props.inventoryData.collection.name}</span><br />
                        <span className="h5" style={{fontSize: "13px", fontWeight: "bold"}}>{this.props.inventoryData.name} #{this.props.inventoryData.token_id}</span> <br />
                    </div>
                </div>
                 </a>
            </div>
        )
    }
}

export default Inventory
