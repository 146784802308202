import React from "react"
import '../assets/css/styles.css';
import Axios from "axios"
import {API_URL} from "../constants/API"
import moment from "moment"
import { getTokenData } from "../redux/actions/token"
import { connect } from "react-redux";
import  { Redirect } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';


class transferDetail extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      getTransferData: [],
      cartNotFound: false,
      getDataSpek: [],
      asssetData: [],
      loading: false,
    }
  }
  
  checkImage = (e) => {
		e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
	}

	imageSwitch(){
		if(this.state.getTokenType === "ETH"){
			//console.log("eth");
			return <img className="" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="coin icon" style={{width: "18px", marginRight: "5px"}} />
		}
		else if(this.state.getTokenType === "ERC20"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.state.getTokenAddress){
					return <img className="" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "18px", marginRight: "5px"}} />
				}
			})
		}

	}

  filledSwitch = (param) => {
		switch(param){
			case 'success':
				return 'badge rounded-pill bg-success'
			default:
        return 'badge rounded-pill bg-danger'
		}
	}

  fetchTransferDetail = () => {
    Axios.get(`${API_URL}/transfers/${this.props.match.params.tx_id}`)
    .then((result) => {
      if(result.data.transaction_id !== "") {
        this.setState({
          getTransferData: result.data,
          getQuantity: result.data.token.data.quantity,
          getDecimals: result.data.token.data.decimals,
          getTokenType: result.data.token.type,
          getTokenAddress: result.data.token.data.token_address,
          getDataSpek: result.data.token.data,
          cartNotFound: false,
        })
        //console.log("cart here")
      }else{
        this.setState({
          cartNotFound: true,
        })
        //console.log("not cart here")
      }
    }).catch(() => {
      alert("Transfer Not Found")
      this.setState({
        cartNotFound: true,
      })
      //console.log(this.state.cartNotFound)
    })
    //console.log()
  }

  fetchAssetDetail = (token_address, token_id) => {
   Axios.get(`${API_URL}/assets/${token_address}/${token_id}`)
   .then((result) => {
     this.setState({
       asssetData: result.data,
       loading: false,
     })
    // console.log(this.state.getDataSpek)
     //console.log(result.data)
   })
   .catch(() => {
     this.setState({
       loading: true,
     })
    
   })
  }

componentDidMount(){
    document.title = `Transaction #${this.props.match.params.tx_id}`;
    this.fetchTransferDetail()
    this.props.getTokenData()
}

    render(){
        return(
            <div className="setBody mx-3 mt-4">
              {
                //
              }
              {
                //console.log(this.state.loading)
                //console.log(this.state.asssetData.image_url)
              }
             {
               this.state.cartNotFound === false ?
               <div>
               {
                 this.state.getDataSpek.token_id !== "" && this.state.getDataSpek.token_addres !== "" && this.state.asssetData.image_url !== null ? 
                 <div>
                   {
                     this.fetchAssetDetail(this.state.getDataSpek.token_address, this.state.getDataSpek.token_id)
                   }
                 {
                   this.state.loading === true ? 
                   <div className="content setBody" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
                  <div class="o1"></div>
                  <div class="o2"></div>
                  <div class="o3"></div>
                  </div>
                  : 
                  <div className="col-12 col-md-12 mx-3">
                    {
                      //console.log(this.state.asssetData.image_url)
                    }
                    <div className="row">
                    <div className="col-12 col-md-4" style={{marginRight: "10px"}}>
                    <div className="card border-0" style={{borderRadius: "0px"}}>
                      <img className="card-img-top setCard" src={this.state.asssetData.image_url} alt="heroes_card" style={{width: "500px"}} />
                    </div>
                    </div>
                    <div className="col-12 col-md-7 mt-4">
                    <div className="shadow-sm p-3 mb-5 bg-white rounded">
                  <strong className="mx-1">Transaction Detail {this.state.getTokenType}</strong>  
                  <hr />
                  <div className="row" style={{wordBreak: "break-all", fontSize: "16px"}}>
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">TX ID</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                      <p className="font" style={{fontWeight: "bold"}}><i class="bi bi-check-circle-fill" style={{color: "#2081e2", marginRight: "5px"}}></i>{parseInt(this.state.getTransferData.transaction_id).toLocaleString(undefined, {maximumFractionDigits: 3})}
                      <Tooltip title="Copy" placement="top">
                      <i className="copy-icon bi bi-files mx-1" onClick={() => {navigator.clipboard.writeText(this.state.getTransferData.transaction_id)}}></i>
                      </Tooltip>
                      </p>
                    </div>
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">Status</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="font" style={{fontWeight: "bold"}}>
                        <span className={this.filledSwitch(this.state.getTransferData.status)}>{this.state.getTransferData.status}</span>
                      </p>
                    </div>
                  
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">Date Time</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="font">{moment(this.state.getTransferData.timestamp).format('LLLL')} <span style={{fontWeight: "bold"}}>({moment(this.state.getTransferData.timestamp).startOf('hour').fromNow()})</span></p>
                    </div>
                    
  
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">From</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="font" style={{wordBreak: "break-all"}}><a href={`../address/${this.state.getTransferData.user}`}>{this.state.getTransferData.user}</a>
                      <Tooltip title="Copy" placement="top">
                      <i className="copy-icon bi bi-files mx-1" onClick={() => {navigator.clipboard.writeText(this.state.getTransferData.user)}}></i>
                      </Tooltip>
                      </p>
                    </div>
                    
  
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">To</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="font" style={{wordBreak: "break-all"}}><a href={`../address/${this.state.getTransferData.receiver}`}>{this.state.getTransferData.receiver}</a>
                      <Tooltip title="Copy" placement="top">
                      <i className="copy-icon bi bi-files mx-1" onClick={() => {navigator.clipboard.writeText(this.state.getTransferData.receiver)}}></i>
                      </Tooltip>
                      </p>
                    </div>
                      
  
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">Amount</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                    <p className="font" style={{fontWeight: "bold"}}>{this.imageSwitch()}{this.state.getQuantity/(10**this.state.getDecimals)} {this.state.asssetData.name}</p>
                    </div>
  
                    
                  </div>
                   </div> 
                    </div>
                    </div>
                  </div>
                 }
                </div>
               :
               <div className="shadow-sm p-3 mb-5 bg-white rounded">
                  <strong className="mx-1">Transaction Detail {this.state.getTokenType}</strong>  
                  <hr />
                  <div className="row" style={{wordBreak: "break-all", fontSize: "16px"}}>
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">TX ID</p>                   
                    </div>
                    <div className="col-12 col-md-9" >
                      <p className="font" style={{fontWeight: "bold"}}><i class="bi bi-check-circle-fill" style={{color: "#2081e2", marginRight: "5px"}}></i>{parseInt(this.state.getTransferData.transaction_id).toLocaleString(undefined, {maximumFractionDigits: 3})}
                      <Tooltip title="Copy" placement="top">
                      <i className="copy-icon bi bi-files mx-1" onClick={() => {navigator.clipboard.writeText(this.state.getTransferData.transaction_id)}}></i>
                      </Tooltip>
                      </p>
                    </div>
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">Status</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="font" style={{fontWeight: "bold"}}>
                        <span className={this.filledSwitch(this.state.getTransferData.status)}>{this.state.getTransferData.status}</span>
                      </p>
                    </div>
                  
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">Date Time</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="font">{moment(this.state.getTransferData.timestamp).format('LLLL')} <span style={{fontWeight: "bold"}}>({moment(this.state.getTransferData.timestamp).startOf('hour').fromNow()})</span></p>
                    </div>
                    
  
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">From</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="font" style={{wordBreak: "break-all"}}><a href={`../address/${this.state.getTransferData.user}`}>{this.state.getTransferData.user}</a>
                      <Tooltip title="Copy" placement="top">
                      <i className="copy-icon bi bi-files mx-1" onClick={() => {navigator.clipboard.writeText(this.state.getTransferData.user)}}></i>
                      </Tooltip>
                      </p>
                    </div>
                    
  
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">To</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="font" style={{wordBreak: "break-all"}}><a href={`../address/${this.state.getTransferData.receiver}`}>{this.state.getTransferData.receiver}</a>
                      <Tooltip title="Copy" placement="top">
                      <i className="copy-icon bi bi-files mx-1" onClick={() => {navigator.clipboard.writeText(this.state.getTransferData.receiver)}}></i>
                      </Tooltip>
                      </p>
                    </div>
                      
  
                    <div className="col-12 col-md-2" style={{color: "#334670", fontWeight: "bold"}}>
                      <p className="font">Amount</p>                   
                    </div>
                    <div className="col-12 col-md-9">
                    <p className="font" style={{fontWeight: "bold"}}>{this.imageSwitch()}{this.state.getQuantity/(10**this.state.getDecimals)}</p>
                    </div>
  
                    
                  </div>
                   </div> 
               }
               </div>
             : 
             <Redirect to ="/" />
             }
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
	}
}

const mapDispatchToProps = {
	getTokenData,
}

export default connect(mapStateToProps, mapDispatchToProps)(transferDetail);