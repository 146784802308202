import React from "react"
import "../assets/css/styles.css"


class Footer extends React.Component{
    render(){
        return(
           <div className="footer-div  mt-5">
             <img src="https://cdn.discordapp.com/attachments/890238141687009300/935367592397385828/logo_ccoes.png" alt="logo_TheCodes" />
             <p>Copyright &copy; 2022 TheCodes. All rights reserved.</p>
            <div className="footer-social">
              <p><a href="mailto:contact@gogscan.com" target="_blank" rel="noreferrer">E-Mail</a></p>
              <p><a href="https://thecodes.dev/" target="_blank" rel="noreferrer">Website</a></p>
              <p><a href="https://t.me/thecodescommunity" target="_blank" rel="noreferrer">Telegram</a></p>
              <p><a href="https://twitter.com/thecodesdev" target="_blank" rel="noreferrer">Twitter</a></p>
              <p><a href="https://facebook.com/groups/Pemburu.Bitcoin.Indonesia/" target="_blank" rel="noreferrer">Facebook</a></p>
            </div>
           </div>
        )
    }
}

export default Footer