const init_state = {
    statusCodeOrder: '',
    statusCodeTrade: '',
    statusCodeTransfer: '',
    statusCodeCollection: '',
    statusCodeToken: '',
    statusCodeInventory: '',
    latestOrder: [],
    latestTrade: [],
    latestTransfer: [],
    latestCollection: [],
    latestInventory: [],
    nextCursor: "",
    prevCursor: "",
    dataRemaining: 0,
    tokenList: [],
    totalOrder: 0,
    totalTrade: 0,
    totalTransfer: 0,
    totalCOllocation: 0,
    pathName: "",
  }
  
  const reducer = (state = init_state, action) =>  {
    switch (action.type) {
      case "GET_PATH_NAME":
        return {...state, pathName: action.data}
      case "GET_SELL_PETS":
        return {...state, statusCodeSellPets: action.status, latestSellPets: action.data.data, nextCursorSellPets: action.data.nextCursor, dataRemainingSellPets: action.data.remaining};
      case "GET_SELL_HEROES":
        return {...state, statusCodeSellHeroes: action.status, latestSellHeroes: action.data.data, nextCursorSellHeroes: action.data.nextCursor, dataRemainingSellHeroes: action.data.remaining};
      case "GET_SELL_AVATAR":
        return {...state, statusCodeSellAvatar: action.status, latestSellAvatar: action.data.data, nextCursorSellAvatar: action.data.nextCursor, dataRemainingSellAvatar: action.data.remaining};  
      case "GET_SELL_OTHER":
        return {...state, statusCodeSellOther: action.status, latestSellOther: action.data.data, nextCursorSellOther: action.data.nextCursor, dataRemainingSellOther: action.data.remaining};  
          
      case "GET_OFFER_HEROES":
        return {...state, statusCodeOfferHeroes: action.status, latestOfferHeroes: action.data.data, nextCursorOfferHeroes: action.data.nextCursor, dataRemainingOfferHeroes: action.data.remaining};  
      case "GET_OFFER_OTHER":
        return {...state, statusCodeOfferOther: action.status, latestOfferOther: action.data.data, nextCursorOfferOther: action.data.nextCursor, dataRemainingOfferOther: action.data.remaining};    
      case "GET_OFFER_PETS":
        return {...state, statusCodeOfferPets: action.status, latestOfferPets: action.data.data, nextCursorOfferPets: action.data.nextCursor, dataRemainingOfferPets: action.data.remaining};      
      case "GET_OFFER_AVATAR":
        return {...state, statusCodeOfferAvatar: action.status, latestOfferAvatar: action.data.data, nextCursorOfferAvatar: action.data.nextCursor, dataRemainingOfferAvatar: action.data.remaining};      
        case "GET_ORDER":
        return {...state, statusCodeOrder: action.status, latestOrder: action.data.data, nextCursorOrder: action.data.nextCursor, dataRemainingOrder: action.data.remaining};
      case "GET_TRADE":
        return {...state, statusCodeTrade: action.status, latestTrade: action.data};
        case "GET_TRANSFER":
          return {...state, statusCodeTransfer: action.status, latestTransfer: action.data.data, nextCursorTransfer: action.data.nextCursor, dataRemainingTransfer: action.data.remaining};
      case "GET_COLLECTION":
        return {...state, statusCodeCollection: action.status, latestCollection: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};
        case "GET_SELL":
          return {...state, statusCodeCollection: action.status, latestCollection: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};
      case "GET_COLLECTION_TRANSFER":
        return {...state, statusCodeCollection: action.status, latestCollection: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};   
        case "GET_COLLECTION_OTHER":
          return {...state, statusCodeCollection: action.status, latestCollection: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};
        case "GET_COLLECTION_PET":
          return {...state, statusCodeCollection: action.status, latestCollection: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};  
        case "GET_COLLECTION_AVATAR":
          return {...state, statusCodeCollection: action.status, latestCollection: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};    
       case "GET_INVENTORY":
          return {...state, statusCodeInventory: action.status, latestInventory: action.data.data, nextCursorInventory: action.data.nextCursor, dataRemainingInventory: action.data.remaining};
     case "RESET_STATUS_ORDER":
          return {...state, statusCodeBlock: action.status}
      case "RESET_STATUS_TRADE":
          return {...state, statusCodeTrade: action.status}
      case "RESET_STATUS_TRANSFER":
          return {...state, statusCodeTransfer: action.status}
      case "RESET_STATUS_COLLECTION":
          return {...state, statusCodeCollection: action.status, latestCollection: []}
      case "RESET_STATUS_TOKEN":
          return {...state, statusCodeToken: action.status}
      default:
          return state;
    }
  }
  
  export default reducer;