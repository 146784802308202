const init_state = {
    tokenList: [],
}

const reducer = (state = init_state, action) => {
    switch (action.type){
        case "SWITCH_IMAGE_TOKEN":
            return { ...state, tokenList: action.payload }
        default:
            return state;
    }
}


export default reducer;