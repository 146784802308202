import React from "react";
import moment from "moment"
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import Axios from "axios"
import {API_URL} from "../constants/API"

class OutTransfer extends React.Component{
    state = {
        loading: false,
        totalload: 0,
        assetData: [],
        newData: "",
        getNFT: [],
    }
    
    checkImage = (e) => {
		e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
	}

	imageSwitch(){
		if(this.props.OutTransferData.token.type === "ETH"){
			//console.log("eth");
			return <img className="mx-1" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="coin icon" style={{width: "18px"}} />
		}
		else if(this.props.OutTransferData.token.type === "ERC20"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.props.OutTransferData.token.data.token_address){
					return <img className="mx-1" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "18px"}} />
				}
			})
		}else if(this.props.OutTransferData.token.type === "ERC721"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.props.OutTransferData.token.data.token_address){
					return <img className="mx-1" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "18px"}} />
				}
			})
		}

	}

    getNFT = () => {
        Axios.get(`${API_URL}/assets?user=${this.props.OutTransferData.user}`)
        .then(nft => {
            console.log(nft.data.imx)
        })
    }

    fetchAsset = () => {
        Axios.get(`${API_URL}/assets/${this.props.OutTransferData.token.data.token_address}/${this.props.OutTransferData.token.data.token_id}`)
        .then((result) => {
           this.setState({
               assetData: result.data,
           })
        })
        .catch(() => {
            //console.log(undefined)
            
        })
    }

    
    componentDidMount(){
        //this.props.getTokenData()
        //this.fetchAsssets(this.props.OutTransferData.token.data.token_address, this.props.OutTransferData.token.data.token_id)
        this.fetchAsset()
        this.getNFT()
        console.log(this.props.OutTransferData)
    }

    render(){
        return (
            <div>
                <div className="row" style={{wordBreak: "break-all", fontSize: "14px"}}>
                    {
                        //console.log(this.state.assetData)
                    }
                    <div className="col-7 col-md-2" style={{fontWeight: "bold"}}>
                    <Link to={`/tx/${this.props.OutTransferData.transaction_id}`}>
                        <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} className="badge rounded-pill bg-success">{parseInt(this.props.OutTransferData.transaction_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                    </Link>
                    </div>
                    <div className="col-5 col-md-2" style={{fontSize: "14px"}}>
                        <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-clock-fill mx-2" style={{color: "#2081e2", fontSize: "12px"}}></i><span className="text-muted">{moment(this.props.OutTransferData.timestamp).fromNow()}</span></p>
                    </div>
                    <div className="col-6 col-md-2" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", fontSize: "12px"}}>
                        <p>
                            <p style={{color: "grey", whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis", fontWeight:"bold"}}>{this.imageSwitch()}
                            {
                                this.props.OutTransferData.token.data.quantity === "1" && this.state.assetData !== undefined ? 
                                    <>
                                    {
                                      
                                    }
                                    <p><img src={this.state.assetData.image_url} alt="image_asset" style={{width: "18px"}} className="rounded mx-1" /> {this.props.OutTransferData.token.data.quantity}{this.state.assetData.name}</p>
                                    </>
                                : <strong><Link to={`/tx/${this.props.OutTransferData.transaction_id}`}>{(this.props.OutTransferData.token.data.quantity/(10**18)).toLocaleString(undefined, {maximumFractionDigits: 3})}</Link></strong> 
                            }
                            </p> 
                        </p>
                    </div>
                    <div className="col-12 col-md-3" style={{color: "purple", fontSize: "14px"}}>
                    <a href={`../address/${this.props.OutTransferData.user}`}>
                        <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-arrow-up mx-2" style={{color: "#DC3545"}}></i>{this.props.OutTransferData.user}</p>
                    </a>
                    </div>

                    <div className="col-12 col-md-3" style={{color: "purple", fontSize: "14px"}}>
                    <a href={`../address/${this.props.OutTransferData.receiver}`}>
                        <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i class="bi bi-arrow-down mx-2" style={{color: "#28A745"}}></i>{this.props.OutTransferData.receiver}</p>
                    </a>
                    </div>
                    <hr />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
	}
}

export default connect(mapStateToProps)(OutTransfer)