import React from "react";
import moment from "moment"
import { getTokenData } from "../redux/actions/token"
import { connect } from "react-redux";

class Order extends React.Component{
    
    checkImage = (e) => {
		e.target.src = "https://design-system.immutable.com/currency_icons/currency--erc20.svg";
	}

	imageSwitch(){
		if(this.props.orderData.buy.type === "ETH"){
			//console.log("eth");
			return <img className="mx-1" src="https://design-system.immutable.com/currency_icons/currency--eth.svg" alt="coin icon" style={{width: "18px"}} />
		}
		else if(this.props.orderData.buy.type === "ERC20"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.props.orderData.buy.data.token_address){
					return <img className="mx-1" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "18px"}} />
				}
			})
		}else if(this.props.orderData.buy.type === "ERC721"){
			return this.props.tokenGlobal.tokenList.map((val) => {
				if(val.token_address === this.props.orderData.buy.data.token_address){
					return <img className="mx-1" src={val.image_url} onError={this.checkImage} alt="coin icon" style={{width: "18px"}} />
				}
			})
		}

	}


    render(){
        return(
            <div className="row mt-2" style={{wordBreak: "break-all", fontSize: "14px"}}>
                {
                    //console.log(this.props.orderData.sell.data.properties)
                }
                <div className="col-6 col-md-2">
                <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} className="badge rounded-pill bg-success">{parseInt(this.props.orderData.order_id).toLocaleString(undefined, {maximumFractionDigits: 3})}</p>
                </div>
                <div className="col-6 col-md-2">
                  <p><i class="bi bi-clock-history" style={{color: "#2081e2"}}></i> {moment(this.props.orderData.timestamp).subtract(10, 'days').calendar()}</p>
                </div>
                <div className="col-6 col-md-2">
               {this.imageSwitch()}
                    {
                     this.props.orderData.buy.data.quantity === "1" ?
                     
                    <p className="mx-2" style={{fontWeight: "bold"}}> <img src={this.props.orderData.buy.data.properties.image_url} alt="image_url" style={{width: "18px"}} className="rounded mx-1" />{this.props.orderData.buy.data.quantity} <span style={{fontSize: "12px"}}>{this.props.orderData.buy.data.properties.name}</span></p>
                    : <strong>{(this.props.orderData.buy.data.quantity/(10**this.props.orderData.buy.data.decimals)).toLocaleString(undefined, {maximumFractionDigits: 3})}</strong>
                    }
                </div>
                <div className="col-6 col-md-2">
                    <p>
                    {
                    this.props.orderData.status === "filled" ?
                    <p></p> :
                    <div>
                    <img src={this.props.orderData.sell.data.properties.image_url} alt="image_url" style={{width: "18px"}} className="rounded mx-1" />  
                    <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: "12px", fontWeight: "bold"}}>{this.props.orderData.sell.data.quantity} {this.props.orderData.sell.data.properties.name}</span>
                    </div>
                    }
                    </p>
                </div>
                <div className="col-6 col-md-4">
                <a href={`../address/${this.props.orderData.user}`}>
                  <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><i className="bi bi-person-fill mx-2" style={{color: "#2081e2"}}></i>{this.props.orderData.user}</p>
                </a>
                </div>
                <hr />
            </div>
        )
    }
}


const mapStateToProps = state => {
	return {
		tokenGlobal: state.token,
	}
}

const mapDispatchToProps = {
	getTokenData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)