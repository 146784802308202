import './App.css';
import React from "react";
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Heroes from "./pages/Heroes";
import Other from "./pages/Other";
import Pets from "./pages/Pets";
import Transfer from "./pages/Transfer";
import Avatar from "./pages/Avatar";
import assetsHeroes from "./pages/assetsHeroes";
import assetsPets from "./pages/assetsPets";
import assetsAvatar from "./pages/assetsAvatar";
import assetsOther from "./pages/assetsOther";
import transferDetail from "./pages/transferDetail";
import tradesDetail from "./pages/tradesDetail";
import MyNavbar from "./components/MyNavbar";
import Footer from "./components/Footer";
import addressDetail from './pages/addressDetail';

class App extends React.Component {
  render(){
    return(
      <BrowserRouter>
        <MyNavbar />
        <Switch>
          <Route component={Heroes} path="/heroes" />
          <Route component={Other} path="/other" />
          <Route component={Avatar} path="/avatar" />
          <Route component={Pets} path="/pets" />
          <Route component={Transfer} path="/transfer" />
          <Route component={MyNavbar} path="/mynavbar" />
          <Route component={assetsHeroes} path="/assets-heroes/:token_id" />
          <Route component={assetsPets} path="/assets-pets/:token_id" />
          <Route component={assetsAvatar} path="/assets-avatar/:token_id" />
          <Route component={assetsOther} path="/assets-other/:token_id" />     
          <Route component={transferDetail} path="/tx/:tx_id" />
          <Route component={tradesDetail} path="/td/:td_id" />
          <Route component={addressDetail} path="/address/:address_id" />
          <Route component={Home} path="/" />
        </Switch>
        <Footer />
      </BrowserRouter>
    )
  }
}


export default App;